/**
 * Important! This is "lazy" context provider. It means that it will not fetch data on mount.
 * Correct: const hris = useGris();
 * Wrong: const hris = useProvider(HrisContext);
 */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { message } from 'antd';

import { showError } from '../../utils/alerts';
import { get, patch, post, remove } from '../../utils/backend';

export interface HrisIntegrationRes {
  id: number;
  provider: string; // "Default",
  enabled: boolean;
  createdAt: string; // "2024-07-15",
  updatedAt: string | null; // '2024-07-15';
  lastSync: string | null; // null;
  organizationId: number; // 107;
  createdBy: number;
  error: any | null;
}

export interface HrisIntegrationUI {
  id: number;
  provider: string;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date | null;
  lastSync: Date | null;
  organizationId: number;
  createdBy: number;
  error: any | null;
}

export const hrisIntegrationResToUI = (res: HrisIntegrationRes): HrisIntegrationUI => ({
  ...res,
  createdAt: new Date(res.createdAt),
  lastSync: res.lastSync ? new Date(res.lastSync) : null,
  updatedAt: res.updatedAt ? new Date(res.updatedAt) : null,
});

interface HrisContextType {
  createIntegration: (token: string) => Promise<void>;
  disableIntegration: (id: number) => Promise<void>;
  enableIntegration: (id: number) => Promise<void>;
  finchSync: () => Promise<void>;
  integrations: HrisIntegrationUI[];
  triggerRefetch: () => void;
  refetchToken: number;
  removeIntegration: (id: number, doRefetch?: boolean) => Promise<void>;
  setRefetchToken: Dispatch<SetStateAction<number>>;
  loading: boolean;
  hasEnabledIntegration: boolean;
}

const HrisContext = createContext<HrisContextType | undefined>(undefined);

interface HrisProviderProps {
  children: React.ReactNode;
}

export const HrisProvider: React.FC<HrisProviderProps> = ({ children }) => {
  // lazy load logic. if 0 - do not refetch.
  const [refetchToken, setRefetchToken] = useState<number>(0);
  const [integrations, setIntegrations] = useState<HrisIntegrationUI[]>([]);
  const [loading, setLoading] = useState(false);

  const handleError = (msg: string, error: Error) => {
    setLoading(false);
    showError(msg);
    throw error;
  };

  const triggerRefetch = () => setRefetchToken((prev) => prev + 1);

  // throwable
  const refetchIntegrations = async () => {
    setLoading(true);
    try {
      const res = await get('/hris_integration');
      setIntegrations((res ?? []).map(hrisIntegrationResToUI));
      setLoading(false);
    } catch (err) {
      handleError('Error fetching HRIS integrations', err as Error);
    }
  };

  // throwable
  const createIntegration = async (token: string): Promise<void> => {
    setLoading(true);
    try {
      await post('/hris_integration', { token });
    } catch (err) {
      handleError('Error creating HRIS integration', err as Error);
    }
    await refetchIntegrations();
    message.success('Changes were saved');
  };

  // throwable
  const removeIntegration = async (id: number, doRefetch = true): Promise<void> => {
    setLoading(true);
    try {
      await remove(`/hris_integration/${id}`);
    } catch (err) {
      handleError(`Can not remove HRIS integration with id: ${id}`, err as Error);
    }
    if (doRefetch) {
      await refetchIntegrations();
      message.success('Changes were saved');
    } else {
      setLoading(false);
    }
  };

  // throwable
  const disableIntegration = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      await patch(`/hris_integration/${id}/disable`);
    } catch (err) {
      handleError(`Can not disable HRIS integration with id: ${id}`, err as Error);
    }
    await refetchIntegrations();
    message.success('Changes were saved');
  };

  // throwable
  const enableIntegration = async (id: number): Promise<void> => {
    setLoading(true);
    try {
      await patch(`/hris_integration/${id}/enable`);
    } catch (err) {
      handleError(`Can not enable HRIS integration with id${id}`, err as Error);
    }
    await refetchIntegrations();
    message.success('Changes were saved');
  };

  // throwable
  const finchSync = async (): Promise<void> => {
    setLoading(true);
    try {
      await post('/finch/sync');
    } catch (err) {
      handleError('Error syncing with Finch', err as Error);
    }
    await refetchIntegrations();
    message.success('Changes were saved');
  };

  useEffect(() => {
    if (refetchToken !== 0) {
      refetchIntegrations();
    }
  }, [refetchToken]);

  const value = useMemo(
    () => ({
      createIntegration,
      disableIntegration,
      enableIntegration,
      finchSync,
      hasEnabledIntegration: integrations.some((i) => i.enabled),
      integrations,
      loading,
      refetchToken,
      removeIntegration,
      setRefetchToken,
      triggerRefetch,
    }),
    [
      disableIntegration,
      enableIntegration,
      createIntegration,
      finchSync,
      integrations,
      loading,
      triggerRefetch,
      refetchToken,
      removeIntegration,
      setRefetchToken,
    ],
  );
  return <HrisContext.Provider value={value}>{children}</HrisContext.Provider>;
};

export const useHris = (): HrisContextType => {
  const context = useContext(HrisContext);

  if (!context) {
    throw new Error('useHris must be used within a HrisContextType');
  }

  useEffect(() => {
    if (context.refetchToken === 0) {
      context.triggerRefetch();
    }
  }, [context.refetchToken]);

  return context;
};
